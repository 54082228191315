<template>
  <v-card flat>
    <v-tabs
      v-model="tab"
      icons-and-text
      fixed-tabs
    >
      <v-tab>
        Skus
        <v-icon>mdi-package</v-icon>
      </v-tab>
      <v-tab>
        General
        <v-icon>mdi-menu</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat class="pa-2">
          <Skus
            :skus="skus"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat class="pa-2">
          <ProductDetail
            :product="product"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Skus from './ProductSkus';
import ProductDetail from './ProductDetail';
export default {
  components: {
    Skus,
    ProductDetail,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    skus() {
      return this.product?.skus ?? [];
    },
  },
};
</script>