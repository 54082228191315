<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div class="d-flex justify-start">
        <div style="width: 150px;">
          <v-select
            v-model="perPage"
            label="Total visible"
            :items="listPerPage"
            @change="resetCurrentPage"
            dense
          />
        </div>
        <div class="ml-2" style="width: 150px;">
          <v-text-field
            v-model="filter"
            label="Buscar"
            @keyup.enter="resetCurrentPage"
            append-icon="mdi-magnify"
            dense
          />
        </div>
      </div>
      <div>
        <v-btn
          @click="getProducts"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createProduct"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Producto
        </v-btn>
        <!-- <v-btn
          @click="addProductByCsv"
          color="success"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          csv
        </v-btn> -->
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="products"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
      :height="heightProducts"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense class="pt-0 pb-0">
            <v-list-item
              link
              @click="openDetails(item)"
            >
              <v-icon left>mdi-menu</v-icon>
              <small>Detalles</small>
            </v-list-item>
            <!-- <v-list-item
              link
              @click="editProduct(item)"
              disabled
            >
              <v-icon left>mdi-pencil</v-icon>
              <small>{{ $t('edit') }} </small>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.product="{ item }">
        <div>
          <div class="text--primary">{{ item.name }}</div>
          <div class="text--secondary" v-if="item.description">{{ item.description }}</div>
        </div>
      </template>
      <template v-slot:item.supplier="{ item }">
        {{ item.supplier }}
      </template>
      <template v-slot:item.brand="{ item }">
        <div v-if="item.brand === null">-</div>
        <div v-else> {{ item.brand.name }} </div>
      </template>
      <template v-slot:item.categories="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="category in item.categories"
            x-small
            :key="`category-${category.id}`"
          >
            {{ category.name }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.suppliers="{ item }">
        <v-chip-group column>
          <v-chip
            v-for="suppplier in item.suppliers"
            x-small
            :key="`suppplier-${suppplier.id}`"
          >
            {{ suppplier.entity.name }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.qty_skus="{ item }">
        {{ item.skus.length }}
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
    <v-navigation-drawer
      v-model="showDetails"
      absolute
      temporary
      right
      width="500"
    >
      <v-card flat>
        <DetailContainer
          :product="currentProduct" 
        />
      </v-card>
    </v-navigation-drawer>
    <ProductEdit
      ref="productEdit"
      @onUpdatedProduct="onUpdatedProduct"
    />
  </div>
</template>

<script>
import ProductServices from '@/store/services/inventory/ProductServices';
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
import DetailContainer from './details/ProductDetailContainer';
import ProductEdit from './ProductEditV2';
// import ProductCreate from './ProductCreate';
// import ProductEdit from './ProductEdit';
export default {
  mixins: [ListMixin],
  components: {
    DetailContainer,
    ProductEdit,
    // ProductCreate
  },
  data() {
    return {
      loading: false,
      products: [],

      currentProduct: {
        id: -1,
      },
      showDetails: false,

      filter: '',

      heightProducts: window.innerHeight - 200,
      modalProduct: false,
      // productEdit: null
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    openDetails(item) {
      this.showDetails = true;
      this.currentProduct = item;
    },
    async getProducts() {
      try {
        const params = {
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'skus,brand,categories,suppliers.entity',
          filter: this.filter,
        };
        this.loading = true;
        const response = await ProductServices.index(this.user.archon.id, params);
        this.products = response.data.data.map(product => ({
          ...product,
          supplier: '-',
        }));
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createProduct() {
      this.$router.push({
        name: 'productCreate',
      });
    },
    addProductByCsv() {
      this.$router.push({
        name: 'orderByCsv',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getProducts();
      else this.currentPage = 1;
    },
    editProduct(value) {
      this.$refs['productEdit'].loadData(value);
    },
    onUpdatedProduct(data) {
      const index = this.products.findIndex(product => product.id === data.product.id);
      this.products.splice(index, 1, data.product);
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Producto',
          sortable: false,
          value: 'product',
        },
        { text: 'Marca', value: 'brand', sortable: false },
        { text: 'Categoria', value: 'categories', sortable: false },
        { text: 'Proveedor', value: 'suppliers', sortable: false },
        { text: 'Skus', value: 'qty_skus', sortable: false },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getProducts();
    },
  },
}
</script>