<template>
  <v-card flat>
    <LoaderThreeBody absolute :loading="loading" />
    <v-card-title>
      <div v-if="brand">{{$t('editBrand')}}</div>
      <div v-else>{{$t('newBrand')}}</div>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid" ref="brandFrom">
        <v-text-field
          v-model="form.name"
          :rules="rules.isRequired"
          :label="$t('name')"
        />
        <v-text-field
          v-model="form.description"
          label="Descripción"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="grey lighten-1"
        @click="cancelSave"
        small
      >
        {{ $t('close') }}
      </v-btn>
      <v-btn
        color="success"
        small
        @click="saveBrand"
      >
        {{$t('save')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BrandServices from '@/store/services/inventory/BrandServices';
import { mapGetters } from 'vuex';
export default {
  props: {
    brand: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      valid: false,
      form: {
        id: null,
        name: '',
        description: ''
      },
      rules: {
        isRequired: [value => !!value || this.$t('requiredField')],
      },
      loading: false,
    };
  },
  methods: {
    async saveBrand() {
      try {
        if (this.$refs.brandFrom.validate()) {
          let response = {};
          this.loading = true;
          if (!this.form.id || this.form.id === '') {
            let dataForm = new FormData()
            for (let key in this.form) {
              dataForm.append(key, this.form[key])
            }
            response = await BrandServices.store(this.user.archon.id, dataForm)
          } else {
            response = await BrandServices.update(this.form.id, this.form)
          }
          this.loading = false;
          this.$notify({
            group: 'petition',
            type: 'success',
            title: 'Marca',
            text: 'Marca agregada exitosamente',
          });
          this.$emit('onSavedBrand', {
            brand: response.data,
          });
          this.resetData();
        }
      } catch (e) {
        this.loading = false;
        console.log(e)
        if (e.response.status > 300) {
          console.log(e)
        }
      }
    },
    cancelSave() {
      this.resetData();
      this.$emit('onCancelSave');
    },
    setData() {
      if (this.brand) {
        this.form.id = this.brand.id
        this.form.name = this.brand.name
        this.form.description = this.brand.description
      }
    },
    resetData() {
      this.form.id = null;
      this.form.name = '';
      this.form.description = '';
      this.$refs.brandFrom.resetValidation();
    },
  },
  watch: {
    brand() {
      this.setData()
    }
  },
  created() {
    this.setData()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  }
}
</script>

<style>

</style>