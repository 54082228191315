<template>
  <v-card outlined class="px-3 pt-3 pb-1">
    <div class="d-flex justify-space-between">
      <v-select
        :label="$t('brand')"
        v-model="selectedBrand"
        :items="brands"
        item-text="name"
        item-value="id"
        return-object
        :rules="rules"
        :loading="loading"
        dense
        :menu-props="{ offsetY: true }"
      />
      <v-btn
        color="primary"
        small
        @click="openDialogBrand"
        rounded
        icon
        outlined
        class="ml-2"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <!-- <v-card-text>
    </v-card-text> -->
    <BrandCreate ref="brandCreate" @onSavedBrand="savedBrand" />
  </v-card>
</template>

<script>
import BrandCreate from "@/views/inventory/brand/BrandCreate";
import BrandServices from "@/store/services/inventory/BrandServices";
import { mapGetters } from "vuex";
export default {
  components: {
    BrandCreate,
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    brandId: {
      default: 0
    }
  },
  data() {
    return {
      brands: [],
      selectedBrand: {},

      loading: false,
    };
  },
  created() {
    this.getBrands();
  },
  methods: {
    async getBrands() {
      try {
        this.brands = [];
        if (this.brandId <= 0) {
          this.selectedBrand = {};
        }
        const params = {
          order_by: "created_at",
          order_direction: "DESC",
        };
        this.loading = true;
        const response = await BrandServices.index(this.user.archon.id, params);
        this.brands = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async getBrand() {
      if (this.brandId > 0) {
        try {
          this.loading = true;
          const response = await BrandServices.show(this.user.archon.id, this.brandId);
          this.setValues(response.data);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    openDialogBrand() {
      this.$refs["brandCreate"].dialog = true;
    },
    savedBrand(data) {
      this.brands.push(data.brand);
      this.selectedBrand = data.brand;
    },
    setValues(value) {
      this.brands.push(value);
      this.selectedBrand = value;
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  watch: {
    selectedBrand() {
      this.$emit("input", this.selectedBrand);
    },
    brandId: {
      deep: true,
      immediate: true,
      handler: 'getBrand'
    }
  },
};
</script>