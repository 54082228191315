<template>
  <v-dialog v-model="dialog" width="50%">
    <v-card>
      <LoaderDotSpinner absolute :loading="loading"/>
      <v-card-title class="amber lighten-2">
        <v-icon left>mdi-pencil-box</v-icon>
        Editar {{ product.name }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" class="mt-4">
          <v-text-field
            v-model="product.name"
            label="Producto"
            :rules="[isRequired]"
          />
          <v-text-field
            v-model="product.description"
            label="Descripción"
          />
          <SelectBrands
            v-model="currentBrand"
            ref="selectBrand"
            :rules="[isRequiredObj]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="grey lighten-1"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          small
          color="warning"
          @click="updateProduct"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductServices from '@/store/services/inventory/ProductServices';
import SelectBrands from '@/views/inventory/brand/SelectBrand';
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    SelectBrands,
  },
  data() {
    return {
      product: {
        name: '',
        description: '',
        type: '',
        status: '',
        brand_id: '',
        include: 'skus,brand,categories,suppliers.entity',
      },

      currentBrand: {},
      dialog: false,
      loading: false,

      // rules
      isRequired: value => !!value || 'Campo requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',
    };
  },
  methods: {
    async updateProduct() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.product.brand_id = this.currentBrand.id;
          const response = await ProductServices.update(this.user.archon.id, this.product.id, this.product);
          this.loading = false;
          this.$emit('onUpdatedProduct', {
            product: response.data,
          });
          this.resetData();
          this.dialog = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    loadData(product) {
      this.product = Object.assign({}, product);
      setTimeout(() => {
        this.$refs['selectBrand'].selectBrand(this.product.brand_id);
      }, 500);
      this.dialog = true;
    },
    closeDialog() {
      this.resetData();
      this.dialog = false;
    },
    resetData() {
      this.product = {
        name: '',
        description: '',
        type: '',
        status: '',
        brand_id: '',
        include: 'skus,brand,categories,suppliers.entity',
      };
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>

<style scoped>

</style>