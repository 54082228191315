<template>
  <v-card flat>
    <v-card-text>
      <div class="d-flex flex-row">
        <b>Nombre:</b> &nbsp;
        <div>{{ product.name }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Description:</b> &nbsp;
        <div>{{ product.description }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Estado:</b> &nbsp;
        <v-chip
          :color="statuses[product.status].color"
          label
          small
          outlined
        >
          {{ statuses[product.status].name }}
        </v-chip>
      </div>
      <div class="d-flex flex-row">
        <b>Creado:</b> &nbsp;
        <div>{{ product.created_at }}</div>
      </div>
      <div class="d-flex flex-row">
        <b>Actualizado:</b> &nbsp;
        <div>{{ product.updated_at }}</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statuses: {
        ACTIVE: {
          name: 'ACTIVO',
          color: 'success',
        },
        INACTIVE: {
          name: 'INACTIVO',
          color: 'error',
        },
      },
    };
  },
  methods: {},
  computed: {},
};
</script>