import ApiService from '../Api';

class BrandServices extends ApiService {
  constructor() {
    super('/archons');
  }

  index(archon, params = {}) {
    return this.api.get(`${this.resource}/${archon}/brands`, { params });
  }

  show(archon, brandId, params = {}) {
    return this.api.get(`${this.resource}/${archon}/brands/${brandId}`, { params });
  }

  store(archon, body) {
    return this.api.post(`${this.resource}/${archon}/brands`, body);
  }
}

export default new BrandServices();