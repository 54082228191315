<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="400"
  >
    <BrandFrom
      @onSavedBrand="savedBrand"
      @onCancelSave="dialog = false"
    />
  </v-dialog>
</template>

<script>
import BrandFrom from './BrandFrom';
export default {
  components: {BrandFrom},
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    savedBrand(data) {
      this.dialog = false
      this.$emit('onSavedBrand', {
        brand: data.brand,
      });
    }
  },
}
</script>