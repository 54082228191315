<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="skus"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.image="{ item }">
        <v-img
          lazy-src="https://picsum.photos/id/11/10/6"
          max-height="50"
          max-width="50"
          :src="getImage(item)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getImage(item) {
      return item?.image ?? 'https://picsum.photos/id/279/5616/3744';
    },
  },
  computed: {
    headers() {
      return [
        { text: 'Imagen', value: 'image', sortable: false },
        { text: 'Sku', value: 'sku', sortable: false },
        { text: 'Minimo Stock', value: 'min_stock', sortable: false },
        { text: 'Maximo Stock', value: 'max_stock', sortable: false },
      ];
    },
  },
};
</script>